@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  position: relative;
  background-image: url(./images/bg.png);
  background-repeat: no-repeat;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 1000px;
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'RoganRegular';
  src: local('RoganRegular'),
    url('./fonts/Rogan/Rogan-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaBold';
  src: local('FuturaBold'),
    url('./fonts/futura/Futura Bold font.ttf') format('truetype');
}

@font-face {
  font-family: 'FuturaMedium';
  src: local('FuturaMedium'),
    url('./fonts/futura/futura medium bt.ttf') format('truetype');
}

@font-face {
  font-family: 'Futura';
  src: local('Futura') url('./fonts/futura/futur.ttf') format('truetype');
}
